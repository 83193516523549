.linear .cmdk-group, .linear .cmdk-list, .linear .cmdk-command {
  display: block;
}
.linear .cmdk-command {
  max-width: 640px;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
  font-family: var(--font-sans);
  box-shadow: var(--cmdk-shadow);
}
.dark .linear .cmdk-command {
  background: linear-gradient(136.61deg, rgb(39, 40, 43) 13.72%, rgb(45, 46, 49) 74.3%);
}
.linear .cmdk-linear-badge {
  height: 24px;
  padding: 0 8px;
  font-size: 12px;
  color: var(--gray11);
  background: var(--gray3);
  border-radius: 4px;
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 16px 16px 0;
}
.linear .cmdk-linear-shortcuts {
  display: flex;
  margin-left: auto;
  gap: 8px;
}
.linear .cmdk-linear-shortcuts kbd {
  font-family: var(--font-sans);
  font-size: 13px;
  color: var(--gray11);
}
.linear .cmdk-input {
  font-family: var(--font-sans);
  border: none;
  width: 100%;
  font-size: 18px;
  padding: 20px;
  outline: none;
  background: var(--bg);
  color: var(--gray12);
  border-bottom: 1px solid var(--gray6);
  border-radius: 0;
  caret-color: #6e5ed2;
  margin: 0;
}
.linear .cmdk-input::placeholder {
  color: var(--gray9);
}
.linear .cmdk-item {
  content-visibility: auto;
  cursor: pointer;
  height: 48px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 16px;
  color: var(--gray12);
  user-select: none;
  will-change: background, color;
  transition: all 150ms ease;
  transition-property: none;
  position: relative;
  width: 100%;
}
.linear .cmdk-item[aria-selected=true] {
  background: var(--gray3);
}
.linear .cmdk-item[aria-selected=true] svg {
  color: var(--gray12);
}
.linear .cmdk-item[aria-selected=true]:after {
  content: "";
  position: absolute;
  left: 0;
  z-index: 123;
  width: 3px;
  height: 100%;
  background: #5f6ad2;
}
.linear .cmdk-item[aria-disabled=true] {
  color: var(--gray8);
  cursor: not-allowed;
}
.linear .cmdk-item:active {
  transition-property: background;
  background: var(--gray4);
}
.linear .cmdk-item:focus {
  outline: none;
}
.linear .cmdk-item:hover, .linear .cmdk-item:focus {
  transition-property: background;
  background: var(--gray2);
}
.linear .cmdk-item + .cmdk-item {
  margin-top: 4px;
}
.linear .cmdk-item svg {
  width: 16px;
  height: 16px;
  color: var(--gray10);
}
.linear .cmdk-list {
  height: min(300px, var(--cmdk-list-height));
  max-height: 400px;
  overflow: auto;
  overscroll-behavior: contain;
  transition: 100ms ease;
  transition-property: height;
}
.linear .cmdk-group-label {
  user-select: none;
  font-size: 12px;
  color: var(--gray11);
  padding: 0 8px;
  display: flex;
  align-items: center;
}
.linear .cmdk-empty {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  white-space: pre-wrap;
  color: var(--gray11);
}
