@tailwind base;

/*TODO: move to dedicated UI components */
label {
  font-size: 18px;
  font-weight: 500;
  color: #1e293b;
}

label > *:first-child {
  margin-top: 0.6rem;
}

label:focus-within {
  color: #059669;
}

input {
  width: 100%;
  height: 46px;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  color: #1e293b;
  margin: 0.4rem 0rem;
  border: #1e293b solid 2px;
  font-size: 16px;
  outline: none;
}

input:focus {
  outline: none;
  border: #059669 solid 3px;
}

select {
  width: 100%;
  height: 46px;
  border: #1e293b solid 2px;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background-color: white;
}

select:focus {
  border: #059669 solid 2px;
}

/*END*/

@tailwind components;
@tailwind utilities;
